
<script lang="ts">
    export interface IProps {
        text?: string;
        href?: string;
        icon?: string;
        divider?: boolean;
        disabled?: boolean;
        target?: Function;
    };

    export interface IEmits {
        (e: "click"): void;
    };
</script>

<script setup lang="ts">
    import { useAsyncComponent } from "o365-vue-utils";
    import { isMobile } from "o365.GlobalState.ts";
    
    const MActionSheetBtn = useAsyncComponent("o365-mobile/MActionSheetBtn");

    const props = defineProps<IProps>();
    const emit = defineEmits<IEmits>();
</script>

<template>
    <template v-if="isMobile">
        <template v-if="divider">
            <!-- TODO: add divider functionality here -->
        </template>
        <template v-else>
            <MActionSheetBtn :target="target" :text="text" :icon="icon" :href="href" @click="emit('click', $event)" :disabled="disabled" v-bind="$attrs">
                <slot />
            </MActionSheetBtn>
        </template>
    </template>
    <template v-else>
        <template v-if="divider">
            <div class="dropdown-divider" />
        </template>
        <template v-else>
            <li>
                <a :ref="target" class="dropdown-item" :class = "disabled ? 'disabled' : ''" :href="href ?? '#'" @click="emit('click', $event)" :disabled="disabled" v-bind="$attrs">
                    <template v-if="icon">
                        <div class="d-inline-block" style="width: 1.5rem;">
                            <i class="bi" :class="icon" v-if="icon" />
                        </div>
                    </template>
                    <slot>
                        <span>{{ text }}</span>
                    </slot>
                </a>
            </li>
        </template>
    </template>
</template>

<style scoped>
    a.dropdown-item.disabled {
        background: var(--bs-gray-100);
        text-decoration: line-through;
    }
</style>
